import PropTypes from 'prop-types';
import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '../../../../../../node_modules/@mui/material/index';
import DialogContentText from '@mui/material/DialogContentText';

import { useNavigate } from '../../../../../../node_modules/react-router-dom/dist/index';
import { Typography } from '@mui/material';
import data from '../../../../../translation.json';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);

    if (!accessToken) {
      navigate('/');
    } else {
      if (index == 1) {
        navigate('/profile');
      } else if (index == 0) {
        navigate('/editprofile');
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const language_id = localStorage.getItem('selected_language');
  const lan_Id = language_id == 1 ? 'arabic' : language_id == 3 ? 'french' : 'english';

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleLogout() {
    // localStorage.removeItem("ACCESS_TOKEN","checklistid","response_check_id","anschoice_update_id");
    localStorage.clear();
    localStorage.setItem('selected_language', language_id);
    navigate('/');
  }

  return (
    <>
      {data &&
        data.map(
          (lang) =>
            [lang] &&
            [lang].map((innerlang) =>
              lan_Id && lan_Id == innerlang.name ? (
                <Grid key={lang} xs={12} md={12} lg={12} sm={12}>
                  <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
                    <ListItemButton
                      dir={language_id == 1 ? 'rtl' : 'ltr'}
                      sx={{
                        border: '1px solid transparent',
                        '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                      }}
                      selected={selectedIndex === 0}
                      onClick={(event) => handleListItemClick(event, 0)}
                    >
                      <ListItemIcon>
                        <EditOutlined />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }}
                        primary={lan_Id && lan_Id == innerlang.name ? innerlang.translations.EditProfile : `Edit Profile`}
                      />
                    </ListItemButton>
                    <ListItemButton
                      dir={language_id == 1 ? 'rtl' : 'ltr'}
                      sx={{
                        border: '1px solid transparent',
                        '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                      }}
                      selected={selectedIndex === 1}
                      onClick={(event) => handleListItemClick(event, 1)}
                    >
                      <ListItemIcon>
                        <UserOutlined />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }}
                        primary={lan_Id && lan_Id == innerlang.name ? innerlang.translations.ViewProfile : `View Profile`}
                      />
                    </ListItemButton>

                    {/* <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
        <ListItemIcon>
          <ProfileOutlined />
        </ListItemIcon>
        <ListItemText primary="Social Profile" />
      </ListItemButton> */}
                    {/* <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
        <ListItemIcon>
          <WalletOutlined />
        </ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItemButton> */}
                    <ListItemButton
                      dir={language_id == 1 ? 'rtl' : 'ltr'}
                      sx={{
                        border: '1px solid transparent',
                        '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                      }}
                      selected={selectedIndex === 2}
                      onClick={handleClickOpen}
                      open={open}
                    >
                      <ListItemIcon>
                        <LogoutOutlined />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }}
                        primary={lan_Id && lan_Id == innerlang.name ? innerlang.translations.Logout : `Logout`}
                      />
                    </ListItemButton>
                    <Dialog
                      fullWidth
                      maxWidth="xs"
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" color="black">
                        {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Logout : `Logout`}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
                          {lan_Id && lan_Id == innerlang.name ? innerlang.translations.AreYouSure : `Are You Sure`} ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          sx={{
                            border: '1px solid transparent',
                            '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                          }}
                          onClick={handleClose}
                        >
                          <Typography color="black"> {lan_Id && lan_Id == innerlang.name ? innerlang.translations.No : `No`}</Typography>
                        </Button>
                        <Button
                          sx={{
                            border: '1px solid transparent',
                            '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                          }}
                          onClick={handleLogout}
                          autoFocus
                        >
                          <Typography color="black">{lan_Id && lan_Id == innerlang.name ? innerlang.translations.Yes : `Yes`}</Typography>
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </List>
                </Grid>
              ) : null
            )
        )}
    </>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
