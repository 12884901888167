// assets
import { DashboardOutlined } from '@ant-design/icons';
import GradingIcon from '@mui/icons-material/Grading';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import GTranslateOutlinedIcon from '@mui/icons-material/GTranslateOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
// icons
const icons = {
  DashboardOutlined,
  GradingIcon,
  LiveHelpOutlinedIcon,
  HandshakeOutlinedIcon,
  FmdGoodOutlinedIcon,
  PeopleAltOutlinedIcon,
  GTranslateOutlinedIcon,
  SettingsOutlinedIcon,
  FlagCircleOutlinedIcon,
  QueryStatsIcon,
  EditNoteOutlinedIcon,
  HelpCenterOutlinedIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  // title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'لوحة القيادة',
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'checklist',
      title: 'لي قائمة تدقيق',
      type: 'item',
      url: '/checklist',
      icon: icons.GradingIcon,
      breadcrumbs: true
    },
    // {
    //   id: 'countries',
    //   title: 'Country',
    //   type: 'item',
    //   url: '/countries',
    //   icon: icons.FlagCircleOutlinedIcon,
    //   breadcrumbs: true
    // },
    {
      id: 'reports',
      title: 'لي التقارير',
      type: 'item',
      url: '/reports',
      // url: '/test',
      icon: icons.HandshakeOutlinedIcon,
      breadcrumbs: true
    },
    // {
    //   id: 'notification',
    //   title: 'Notifications',
    //   type: 'item',
    //   url: '/notifications',
    //   icon: icons.FmdGoodOutlinedIcon,
    //   breadcrumbs: true
    // },
    // {
    //   id: 'language',
    //   title: 'Language',
    //   type: 'item',
    //   url: '/language',
    //   icon: icons.GTranslateOutlinedIcon,
    //   breadcrumbs: true
    // },
    {
      id: 'settings',
      title: 'إعدادات',
      type: 'item',
      url: '/settings',
      icon: icons.SettingsOutlinedIcon,
      breadcrumbs: true
    },
    {
      id: 'FAQ',
      title: 'التعليمات',
      type: 'item',
      url: '/frequentquestions',
      icon: icons.QueryStatsIcon,
      breadcrumbs: true
    },
    {
      id: 'Feedback',
      title: 'تعليق',
      type: 'item',
      url: '/Feedback',
      icon: icons.EditNoteOutlinedIcon,
      breadcrumbs: true
    }
    // {
    //   id: 'Help',
    //   title: 'يساعد',
    //   type: 'item',
    //   url: '/help',
    //   icon: icons.HelpCenterOutlinedIcon,
    //   breadcrumbs: true
    // }
  ]
};

export default dashboard;
